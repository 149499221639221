require('./bootstrap');

import Alpine from 'alpinejs';
import Clipboard from "@ryangjchandler/alpine-clipboard"
import focus from '@alpinejs/focus'

Alpine.plugin(Clipboard.configure({
    onCopy: () => {
        emitCopyToClipboard();
    }
}))
Alpine.plugin(focus);
window.Alpine = Alpine;

Alpine.start();

function emitCopyToClipboard() {
    Livewire.emit('CopiedToClipboard');
    setTimeout(function () {
        Livewire.emit('CopiedToClipboardDone');
    }, 5000);
}
